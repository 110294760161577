<template>
    <div class="my-wardrobes">
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <div class="banners">
            <div class="with-half pull-left">
                <img class="title-img" src="@/assets/images/wardrobes/logo.png" alt="">
                <div class="ios-img">
                    <img src="@/assets/images/wardrobes/ios.png" alt="">
                    <div class="ios-qrcode">
                        <img src="@/assets/images/wardrobes/ios-qrcode.png" alt="">
                    </div>
                </div>

            </div>
            <div class="with-half pull-left">
                <img class="yuan4" src="@/assets/images/wardrobes/yuan4.png" alt="">
                <img class="app-img" src="@/assets/images/wardrobes/app.png" alt="">
                <img class="yuan1" src="@/assets/images/wardrobes/yuan1.png" alt="">
            </div>
        </div>

        <div class="content">
            <img class="content-title-img" src="@/assets/images/wardrobes/content-title.png" alt="">
            <div class="content-row">
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s1.png" alt="">
                </div>
                <div class="with-half pull-left text-left">
                    <img class="s-title" src="@/assets/images/wardrobes/s1-title.png" alt="">
                    <p class="s-text">方便快捷的天气功能板块</p>
                </div>
            </div>

            <div class="content-row">
                <div class="with-half pull-left text-right">
                    <img class="s-title" src="@/assets/images/wardrobes/s2-title.png" alt="">
                    <p class="s-text">方便简洁的单品管理</p>
                </div>
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s2.png" alt="">
                </div>
            </div>


            <div class="content-row">
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s3.png" alt="">
                </div>
                <div class="with-half pull-left text-left">
                    <img class="s-title" src="@/assets/images/wardrobes/s3-title.png" alt="">
                    <p class="s-text">高效便捷的服饰上传</p>
                </div>
            </div>


            <div class="content-row">
                <div class="with-half pull-left text-right">
                    <img class="s-title" src="@/assets/images/wardrobes/s4-title.png" alt="">
                    <p class="s-text">随时方便在线穿搭互助</p>
                </div>
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s4.png" alt="">
                </div>
            </div>

            <div class="content-row">
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s5.png" alt="">
                </div>
                <div class="with-half pull-left text-left">
                    <img class="s-title" src="@/assets/images/wardrobes/s5-title.png" alt="">
                    <p class="s-text">穿搭日历在线管理</p>
                </div>
            </div>

            <div class="content-row">
                <div class="with-half pull-left text-right">
                    <img class="s-title" src="@/assets/images/wardrobes/s6-title.png" alt="">
                    <p class="s-text">在线多衣橱管理</p>
                </div>
                <div class="with-half pull-left">
                    <img class="s-img" src="@/assets/images/wardrobes/s6.png" alt="">
                </div>
            </div>

        </div>

        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LRightContact from "../../components/LRightContact";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";

    export default {
        name: "Index",
        components: {LIcp, LFooter, LRightContact, MainNav}
    }
</script>

<style lang="less" scoped>
    .banners {
        background-color: black;
        padding-top: 170px;
        padding-bottom: 90px;
        overflow: hidden;

        .with-half {
            position: relative;
            text-align: left;
        }
    }

    .app-img {
        margin-left: 204px;
        width: 400px;
        position: relative;
        z-index: 2;
    }

    .yuan4 {
        position: absolute;
        left: 0;
        top: 219px;
        z-index: 1;
        width: 582px;
    }

    .yuan1 {
        position: absolute;
        right: 0;
        top: 0;
        width: 350px;
    }

    .title-img {
        float: right;
        margin-right: 45px;
        width: 550px;
    }

    .content {
        padding: 150px 0;
        text-align: center;
        width: 1067px;
        margin: 0 auto;
    }

    .content-title-img {
        width: 490px;
        margin-bottom: 150px;
    }

    .my-wardrobes {
        background-color: black;
    }

    .content-row {
        overflow: hidden;
    }

    .s-img {
        width: 100%;
    }

    .s-title {
        width: 130px;
        margin-top: 92px;
        margin-bottom: 43px;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .s-text {
        font-size: 48px;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 67px;
    }

    .ios-img {
        position: absolute;
        right: 413px;
        top: 410px;
        width: 184px;
        cursor: pointer;

        img {
            width: 100%;
        }

        &:hover .ios-qrcode {
            display: block;
        }
    }

    .ios-qrcode {
        width: 280px;
        height: 280px;
        background-color: rgba(0, 0, 0, 1);
        border: 1px solid;
        border-image: linear-gradient(136deg, rgba(104, 198, 237, 1), rgba(151, 244, 187, 1)) 1 1;
        padding: 10px;
        position: absolute;
        top: -307px;
        left: 0;
        display: none;
    }
</style>